import React from 'react';
import StickyEl from 'react-sticky-el';
import { css } from 'glamor';
import CustomPropTypes from '../../utils/customPropTypes';

const Sticky = ({ children }) => (
  <StickyEl stickyClassName={css({ zIndex: '1000' }).toString()}>
    <div>{children}</div>
  </StickyEl>
);

Sticky.propTypes = {
  children: CustomPropTypes.children.isRequired,
};

export default Sticky;
