import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import PaneImage from '../../components/PaneImage';

const SidewalkImage = ({ blur, ...rest }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "sidewalk.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <PaneImage
        fluid={data.placeholderImage.childImageSharp.fluid}
        blur={blur}
        {...rest}
      />
    )}
  />
);

SidewalkImage.defaultProps = {
  blur: null,
};

SidewalkImage.propTypes = {
  blur: PropTypes.number,
};

export default SidewalkImage;
