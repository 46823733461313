import React from 'react';
import { css } from 'glamor';
import { lt } from '../../styles/mediaQueries';
import CustomPropTypes from '../../utils/customPropTypes';

const GridRow = ({ children }) => (
  <div
    className={css({
      display: 'flex',
      margin: '0 -24px',
      [lt.lg]: {
        display: 'block',
        margin: '0 auto',
      },
    })}
  >
    {children}
  </div>
);

GridRow.propTypes = {
  children: CustomPropTypes.children.isRequired,
};

export default GridRow;
