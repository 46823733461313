import React from 'react';

import Text from '../../components/Text';

const Address = ({
  name,
  street,
  city,
  state,
  zipCode,
  websiteUrl,
  mapsUrl,
  ...rest
}) => (
  <>
    <Text small {...rest}>
      {name}
      <br />
      {street}
      <br />
      {city}, {state} {zipCode}
      <br />
      {websiteUrl && (
        <Text tagName="span" italic>
          <a href={websiteUrl}>Website.</a>
        </Text>
      )}{' '}
      {mapsUrl && (
        <Text tagName="span" italic>
          <a href={mapsUrl}>Google Maps.</a>
        </Text>
      )}
    </Text>
  </>
);

export default Address;
