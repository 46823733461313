import React from 'react';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from '../../utils/cookie';

class CookieManager extends React.Component {
  state = {
    hasCookie: this.getHasCookie(),
  };

  getHasCookie() {
    const { cookieName } = this.props;
    return getCookie({ name: cookieName });
  }

  handleRequestSetCookie = () => {
    const { cookieName, cookieDaysToExpire, cookieValue } = this.props;
    setCookie({
      name: cookieName,
      value: cookieValue,
      daysToExpire: cookieDaysToExpire,
    });
    this.setState({ hasCookie: this.getHasCookie() });
  };

  render() {
    const { render } = this.props;
    const { hasCookie } = this.state;
    return render({
      onRequestSetCookie: this.handleRequestSetCookie,
      hasCookie,
    });
  }
}

CookieManager.propTypes = {
  cookieName: PropTypes.string.isRequired,
  cookieDaysToExpire: PropTypes.number.isRequired,
  cookieValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  render: PropTypes.func.isRequired,
};

export default CookieManager;
