import React from 'react';
import PropTypes from 'prop-types';
import Scrollchor from 'react-scrollchor';
import { css } from 'glamor';
import { white, grey, rgba } from '../../styles/colors';
import { regular } from '../../styles/fonts';
import CustomPropTypes from '../../utils/customPropTypes';

const getStyles = () => ({
  backgroundColor: grey.dark,
  padding: '24px',
  textAlign: 'center',
  '& a': {
    ...regular,
    color: rgba(white.primary, 0.5),
    margin: '0 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '14px',
    letterSpacing: '5px',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRigh: 0,
    },
    '&.current': {
      color: white.primary,
    },
  },
});

const className = css(getStyles());

const NavBar = ({ entries, current }) => (
  <nav className={className}>
    {entries.map(({ title, to, href }) =>
      React.createElement(
        to ? Scrollchor : 'a',
        {
          key: to || href,
          to,
          href,
          target: href ? '_blank' : undefined,
          className: current && to === current ? 'current' : '',
        },
        title
      )
    )}
  </nav>
);

NavBar.propTypes = {
  entries: CustomPropTypes.navEntries.isRequired,
  current: PropTypes.string,
};

NavBar.defaultProps = {
  current: null,
};

export default NavBar;
