import React from 'react';
import PropTypes from 'prop-types';

import GridCol from '../../components/GridCol';
import GridRow from '../../components/GridRow';
import Text from '../../components/Text';

const ContentRow = ({ title, subtitle, renderContents }) => (
  <GridRow>
    <GridCol width="50%">
      <Text tagName="h2">{title}</Text>
      <Text accent>{subtitle}</Text>
    </GridCol>
    <GridCol width="50%">{renderContents()}</GridCol>
  </GridRow>
);

ContentRow.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  renderContents: PropTypes.func,
};

ContentRow.defaultProps = {
  renderContents: () => null,
};

export default ContentRow;
