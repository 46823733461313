import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { lt } from '../../styles/mediaQueries';
import CustomPropTypes from '../../utils/customPropTypes';
import { maxContentWidth } from '../../styles/shared';

const GridCol = ({ children, width }) => (
  <div
    className={css({
      width,
      padding: '0 24px',
      [lt.lg]: {
        width: '100%',
        maxWidth: maxContentWidth,
        margin: '0 auto',
        padding: 0,
      },
    })}
  >
    {children}
  </div>
);

GridCol.propTypes = {
  children: CustomPropTypes.children.isRequired,
  width: PropTypes.string.isRequired,
};

export default GridCol;
