import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import Box from '../Box';
import { cyan } from '../../styles/colors';

const NewsFooter = ({ visible, render, onRequestClose }) =>
  visible ? (
    <div
      className={css({
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
      })}
    >
      <Box backgroundColor={cyan.primary}>
        <div
          className={css({
            maxWidth: '1200px',
            margin: '0 auto',
          })}
        >
          <>{render({ onRequestClose })}</>
        </div>
      </Box>
    </div>
  ) : null;

NewsFooter.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
};

export default NewsFooter;
