import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import CustomPropTypes from '../../utils/customPropTypes';
import { lt } from '../../styles/mediaQueries';
import { pageRowPadding, altPageRowPadding } from '../../styles/shared';

const getPadding = small => {
  const vertPadding = small ? altPageRowPadding : pageRowPadding;
  return {
    padding: `${vertPadding.lg.y} ${pageRowPadding.lg.x}`,
    [lt.sm]: {
      padding: `${vertPadding.sm.y} ${pageRowPadding.sm.x}`,
    },
  };
};

const PageRow = ({ id, small, children }) => (
  <div id={id} className={css(getPadding(small))}>
    <div
      className={css({
        maxWidth: '1200px',
        margin: '0 auto',
      })}
    >
      {children}
    </div>
  </div>
);

PageRow.propTypes = {
  id: PropTypes.string,
  small: PropTypes.bool,
  children: CustomPropTypes.children.isRequired,
};

PageRow.defaultProps = {
  id: undefined,
  small: false,
};

export default PageRow;
