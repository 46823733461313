import React from 'react';
import PropTypes from 'prop-types';
import ReactScrollspy from 'react-scrollspy';

class Scrollspy extends React.Component {
  state = {
    current: null,
  };

  handleUpdate = target => {
    if (target) {
      this.setState({ current: target.id });
    }
  };

  render() {
    const { render, items, ...rest } = this.props;
    const { current } = this.state;
    return (
      <ReactScrollspy items={items} onUpdate={this.handleUpdate} {...rest}>
        {render({ current })}
      </ReactScrollspy>
    );
  }
}

Scrollspy.propTypes = {
  render: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Scrollspy;
