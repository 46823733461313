import React from 'react';
import { Animated } from 'react-animated-css';

import { css } from 'glamor';
import BannerText from '../components/BannerText';
import Box from '../components/Box';
import Button from '../components/Button';
import NewsFooter from '../components/NewsFooter';
import PageRow from '../components/PageRow';
import Text from '../components/Text';
import Layout from '../components/Layout';

import ContentRow from '../compositions/ContentRow';
import ScheduleItem from '../compositions/ScheduleItem';
import ScheduleParent from '../compositions/ScheduleParent';

import SidewalkImage from '../compositions/SidewalkImage';
import SillyImage from '../compositions/SillyImage';

import { grey, white } from '../styles/colors';
import LarryImage from '../compositions/LarryImage';
import NavBar from '../components/NavBar';
import Sticky from '../components/Sticky';
import Scrollspy from '../components/Scrollspy';
import Menu from '../components/Menu/Menu';
import { lt, gte } from '../styles/mediaQueries';
import Address from '../compositions/Address';

import CookieManager from '../containers/CookieManager';

const scrollTargets = {
  schedule: 'schedule',
  accommodations: 'accommodations',
  questions: 'questions',
  rsvp: 'rsvp',
};

const scrollTargetsArr = Object.values(scrollTargets);

const cookieSeed = 2;
const cookieBaseName = 'hasSeenNewsBanner';
const cookieName = `${cookieBaseName}-${cookieSeed}`;

const forceShowNewsFooter = false;

const getNavEntries = details => [
  {
    to: scrollTargets.schedule,
    title: 'Schedule',
  },
  {
    to: scrollTargets.accommodations,
    title: 'Accommodations',
  },
  {
    to: scrollTargets.questions,
    title: 'Questions',
  },
  {
    href: details.vendors.registry.url,
    title: 'Registry',
  },
];

const IndexPage = () => (
  <Layout>
    {({ accessGranted, details }) => (
      <>
        <Box
          fullBleed
          backgroundColor={grey.primary}
          sizeToViewport
          centerContents
        >
          <PageRow>
            {accessGranted && (
              <Animated animationIn="fadeIn">
                <BannerText>
                  {details.people.bride.firstName} and{' '}
                  {details.people.groom.firstName} are getting married and would
                  love nothing more than for you to join them and their families
                  to celebrate their love.
                </BannerText>
              </Animated>
            )}
          </PageRow>
        </Box>
        {accessGranted && (
          <>
            <div className={css({ [lt.md]: { display: 'none' } })}>
              <Scrollspy
                items={scrollTargetsArr}
                componentTag="div"
                render={({ current }) => (
                  <Sticky>
                    <NavBar
                      current={current}
                      entries={getNavEntries(details)}
                    />
                  </Sticky>
                )}
              />
            </div>

            <div className={css({ [gte.md]: { display: 'none' } })}>
              <Menu entries={getNavEntries(details)} />
            </div>

            <CookieManager
              cookieName={cookieName}
              cookieValue="true"
              cookieDaysToExpire={28}
              render={({ hasCookie, onRequestSetCookie }) => (
                <NewsFooter
                  visible={forceShowNewsFooter || !hasCookie}
                  onRequestClose={onRequestSetCookie}
                  render={({ onRequestClose }) => (
                    <>
                      <Text color={white.primary}>
                        We&apos;ve added a bunch of new information about Friday
                        Night Drinks, Transportation, Attire, Parking, and lots
                        of other stuff. Learn more below!
                      </Text>
                      <Button rank="outline" onClick={onRequestClose}>
                        Got it!
                      </Button>
                    </>
                  )}
                />
              )}
            />

            <PageRow id={scrollTargets.schedule}>
              <Text textAlign="center" marginBottom="144px" accent>
                Schedule of Events
              </Text>
              <ScheduleParent>
                <ScheduleItem
                  title="Welcome Drinks."
                  date={details.events.welcomeNight.date}
                  time={details.events.welcomeNight.time}
                  endTime={details.events.welcomeNight.endTime}
                  location={details.events.welcomeNight.venue.name}
                  address={details.events.welcomeNight.venue.address}
                  renderContents={() => (
                    <>
                      <Text>
                        We want to see as much of you as we can! If you can make
                        it to town a little early, join us for drinks and hangs.
                      </Text>
                      <Text small>
                        <Text tagName="span" italic>
                          Casual vibe. Wear what you want!
                        </Text>
                      </Text>
                    </>
                  )}
                />
                <ScheduleItem
                  title="The Wedding."
                  date={details.events.wedding.date}
                  time={details.events.wedding.time}
                  endTime={details.events.wedding.endTime}
                  location={details.events.wedding.venue.name}
                  address={details.events.wedding.venue.address}
                  renderContents={() => (
                    <>
                      <Text>
                        The whole deal. Ceremony. Cocktails. Dinner. Dancing.
                        We&apos;re small-wedding people having a big wedding...
                        Oops.
                      </Text>
                      <Text small>
                        <Text tagName="span" italic>
                          Cocktail attire, please.
                        </Text>
                      </Text>
                      <Text small>
                        <Text tagName="span" italic>
                          Shuttle service will be provided to{' '}
                          {details.events.wedding.venue.name} departing{' '}
                          {details.hotel.name} at 4:30pm. Shuttles will be
                          available to return to {details.hotel.name} with the
                          last one departing at 11:00pm.
                        </Text>
                      </Text>
                      <Text small>
                        <Text tagName="span" italic>
                          Planning to drive? Valet service will be available at{' '}
                          {details.events.wedding.venue.name} as well. Please
                          drive responsibly!
                        </Text>
                      </Text>
                    </>
                  )}
                />
                <ScheduleItem
                  title="Sunday Brunch."
                  date={details.events.brunch.date}
                  time={details.events.brunch.time}
                  endTime={details.events.brunch.endTime}
                  location={details.events.brunch.venue.name}
                  address={details.events.brunch.venue.address}
                  renderContents={() => (
                    <>
                      <Text>
                        Breakfast is the most important meal of the day. Come
                        refuel and say goodbye before you get outta Dodge.
                      </Text>
                      <Text small>
                        <Text tagName="span" italic>
                          Extremely casual vibe. Wear whatever you can throw on.
                        </Text>
                      </Text>
                    </>
                  )}
                />
              </ScheduleParent>
            </PageRow>
            <SidewalkImage />
            <PageRow id={scrollTargets.accommodations}>
              <ContentRow
                title="Accommodations."
                subtitle={details.hotel.name}
                renderContents={() => (
                  <>
                    <Text>
                      We&apos;ll be using{' '}
                      <Text tagName="span" italic>
                        {details.hotel.name}
                      </Text>{' '}
                      as our home base for the weekend, and we welcome you to do
                      the same.
                    </Text>
                    <Text>
                      Obviously, hotels come in all shapes and sizes (and price
                      ranges). There are many other hotels nearby and in
                      neighboring areas, and Airbnb is a great option as well.
                    </Text>
                    <Address
                      name={details.hotel.name}
                      {...details.hotel.address}
                    />
                    <Text small>
                      Transportation will be provided from {details.hotel.name}{' '}
                      to {details.events.wedding.venue.name} (and back), and
                      brunch will be hosted at{' '}
                      {details.events.brunch.venue.name} on Sunday morning.
                    </Text>
                    <Text tagName="h5" marginTop="64px">
                      Parking
                    </Text>
                    <Text small>
                      The hotel recommends that guests park at the{' '}
                      {details.parking.name} nearby. Ask for a receipt at the
                      concierge and present it at the garage for a special rate
                      of $18/day.
                    </Text>
                    <Address
                      name={details.parking.name}
                      {...details.parking.address}
                      marginBottom={0}
                    />
                  </>
                )}
              />
            </PageRow>
            <SillyImage />
            <PageRow id={scrollTargets.questions}>
              <ContentRow
                title="The more you know..."
                subtitle="Questions we've gotten"
                renderContents={() => (
                  <>
                    <Text bold marginBottom="16px">
                      How do I get to the party?
                    </Text>
                    <Text small marginBottom="16px">
                      Great question! We&apos;ll be providing bus transportation
                      from {details.hotel.name} to{' '}
                      {details.events.wedding.venue.name} and back! The shuttle
                      leaves the hotel at 4:30pm and the last shuttle will leave
                      the venue at 11:00pm.
                    </Text>
                    <Text small>
                      Otherwise, {details.events.wedding.venue.name} is{' '}
                      <a
                        href={
                          details.events.wedding.venue.links.subwayDirections
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        very accessible via the subway
                      </a>{' '}
                      while ride-sharing apps are a good option. We&apos;ll also
                      have valet service available if you wish to drive.
                    </Text>{' '}
                    <Text bold marginBottom="16px">
                      What do I wear?
                    </Text>
                    <Text small marginBottom="16px">
                      Cocktail attire - the{' '}
                      <Text tagName="span" italic>
                        fun kind!
                      </Text>
                    </Text>
                    <Text small marginBottom="16px">
                      Think a night out on the town! Suit, dress,
                      jacket-and-tie, a skirt. Maybe even a fancy jumpsuit!? Put
                      on the sort of thing that&apos;ll have you looking in the
                      mirror and saying{' '}
                      <Text tagName="span" italic>
                        &quot;Damn! I clean up well!&quot;
                      </Text>
                    </Text>
                    <Text small>Don&apos;t worry - you look great.</Text>
                    <Text bold marginBottom="16px">
                      Isn&apos;t this the same weekend as Pride?
                    </Text>
                    <Text small>
                      YES! New York City is hosting{' '}
                      <Text tagName="span" italic>
                        World Pride
                      </Text>{' '}
                      this weekend. We hope you&apos;ll find time to participate
                      in some of the festivities. For our wedding, plan some
                      extra time to get around the city in case of traffic and
                      subway delays. Have fun!
                    </Text>
                    <Text bold marginBottom="16px">
                      Are kiddos invited?
                    </Text>
                    <Text small>
                      We love the little ones but this party is adults only.
                      We&apos;d love to see them at Sunday Brunch if they can
                      tag along! :)
                    </Text>
                    <Text bold marginBottom="16px">
                      Are you registered anywhere?
                    </Text>
                    <Text small>
                      Yup! We&apos;re at{' '}
                      <a
                        href={details.vendors.registry.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {details.vendors.registry.name}
                      </a>
                      .
                    </Text>
                    <Text bold marginBottom="16px">
                      Will {details.people.dog.firstName} be there?
                    </Text>
                    <Text small marginBottom={0}>
                      Obviously.
                    </Text>
                  </>
                )}
              />
            </PageRow>
            <LarryImage />
            <Box fullBleed backgroundColor={grey.dark}>
              <PageRow small>
                <Text small light marginBottom={0}>
                  Photos with love by the amazing{' '}
                  <a
                    href={details.vendors.photographer.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {details.vendors.photographer.name}
                  </a>
                  .
                </Text>
              </PageRow>
            </Box>
          </>
        )}
      </>
    )}
  </Layout>
);

export default IndexPage;
