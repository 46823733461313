import React from 'react';
import { css } from 'glamor';
import { shevy } from '../../styles/spacing';
import { lt } from '../../styles/mediaQueries';
import CustomPropTypes from '../../utils/customPropTypes';
import { heading } from '../../styles/fonts';
import { white } from '../../styles/colors';

const getStyles = () => ({
  ...shevy.h1,
  ...heading,
  color: white.primary,
  [lt.lg]: shevy.h2,
  [lt.md]: shevy.h3,
  [lt.sm]: shevy.h4,
  marginBottom: `0 !important`,
});

const className = css(getStyles());

const BannerText = ({ children }) => <h1 className={className}>{children}</h1>;

BannerText.propTypes = {
  children: CustomPropTypes.children.isRequired,
};

export default BannerText;
