import React from 'react';
import { css } from 'glamor';
import CustomPropTypes from '../../utils/customPropTypes';
import { lt } from '../../styles/mediaQueries';
import { pageRowPadding } from '../../styles/shared';

const ScheduleParent = ({ children }) => (
  <div>
    {React.Children.map(children, (child, i) => (
      <div
        className={css({
          marginBottom: i === children.length - 1 ? 0 : pageRowPadding.lg.y,
          [lt.md]: {
            marginBottom: i === children.length - 1 ? 0 : pageRowPadding.sm.y,
          },
        })}
      >
        {child}
      </div>
    ))}
  </div>
);

ScheduleParent.propTypes = {
  children: CustomPropTypes.children.isRequired,
};

export default ScheduleParent;
