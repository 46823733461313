import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import SmoothCollapse from 'react-smooth-collapse';
import Scrollchor from 'react-scrollchor';
import { white, grey, rgba } from '../../styles/colors';
import { regular } from '../../styles/fonts';
import CustomPropTypes from '../../utils/customPropTypes';

const MenuButton = ({ onClick, open }) => (
  <button
    type="button"
    onClick={onClick}
    className={css({
      position: 'relative',
      zIndex: '100',
      backgroundColor: rgba(white.primary, 0.5),
      border: 0,
      borderRadius: '8px',
      padding: '5px',
      width: '48px',
      height: '48px',
      textAlign: 'center',
      // Default transition on smooth-collapse
      transition: 'all 0.25s ease',
      transform: open ? 'rotate(90deg)' : undefined,
    })}
  >
    <div
      className={css({
        display: 'inline-block',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: grey.primary,
        boxShadow: `0px 10px 0px ${grey.primary}, 0px 20px 0px ${grey.primary}`,

        // Not sure what's going on here.
        position: 'relative',
        top: '-11px',
      })}
    />
  </button>
);

MenuButton.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const MenuItem = ({ to, href, title, onClick }) => (
  <li
    className={css({
      fontSize: '16px',
      ...regular,
      letterSpacing: '6px',
      margin: '16px 0',
      textTransform: 'uppercase',
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '& a': {
        color: 'white !important',
        textDecoration: 'none !important',
      },
    })}
  >
    {React.createElement(
      to ? Scrollchor : 'a',
      to
        ? {
            to,
            beforeAnimate: onClick,
          }
        : {
            href,
            target: '_blank',
            onClick,
          },
      title
    )}
  </li>
);

MenuItem.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

MenuItem.defaultProps = {
  to: undefined,
  href: undefined,
};

const Menu = ({ entries, open, onMenuClick, onEntryClick }) => (
  <>
    <div className={css({ position: 'fixed', bottom: '24px', right: '24px' })}>
      <MenuButton onClick={onMenuClick} open={open} />
    </div>
    <div
      className={css({ position: 'fixed', top: 0, right: 0, width: '100%' })}
    >
      <SmoothCollapse expanded={open}>
        <nav
          className={css({
            width: '100%',
            textAlign: 'center',
            backgroundColor: grey.dark,
            padding: '24px',
          })}
        >
          <ul>
            {entries.map(props => (
              <MenuItem
                key={props.to || props.href}
                {...props}
                onClick={onEntryClick}
              />
            ))}
          </ul>
        </nav>
      </SmoothCollapse>
    </div>
  </>
);

class MenuContainer extends React.Component {
  state = {
    open: false,
  };

  handleMenuClick = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  handleEntryClick = () => {
    this.setState({ open: false });
  };

  render() {
    const { entries } = this.props;
    const { open } = this.state;
    return (
      <Menu
        entries={entries}
        open={open}
        onMenuClick={this.handleMenuClick}
        onEntryClick={this.handleEntryClick}
      />
    );
  }
}

MenuContainer.propTypes = {
  entries: CustomPropTypes.navEntries.isRequired,
};

export default MenuContainer;
