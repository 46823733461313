import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import { css } from 'glamor';

const important = val => `${val} !important`;

const fullBleedStyles = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
};

const getClassName = ({ fit = 'cover', blur, position = '50% 50%' }) =>
  css({
    width: '100%',
    height: '100%',
    maxHeight: '500px',
    zIndex: -1,

    '& img': {
      // Sets image to full-bleed inside container
      objectFit: important(fit),
      objectPosition: important(position),

      // Manages blur
      ...(blur && { filter: `blur(${blur}px) !important` }),
      transition: 'all 0.5s ease !important',
    },
  });

const PaneImage = ({ children, fluid, ...rest }) => (
  <>
    <GatsbyImage className={getClassName(rest)} fluid={fluid} />
    {children && (
      <div
        className={css({
          ...fullBleedStyles,
          zIndex: 0,
        })}
      >
        {children}
      </div>
    )}
  </>
);

PaneImage.defaultProps = {
  children: null,
};

PaneImage.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  fluid: PropTypes.object.isRequired,
};

export default PaneImage;
