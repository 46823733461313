import React from 'react';

import Address from '../Address';
import ContentRow from '../ContentRow';
import Text from '../../components/Text';

const getSubtitle = ({ date, time, endTime }) => {
  const timeText = endTime ? `${time} - ${endTime}` : time;
  return `${date}. ${timeText}.`;
};

const ScheduleItem = ({
  title,
  date,
  time,
  endTime,
  location,
  renderContents,
  address,
}) => (
  <ContentRow
    title={title}
    subtitle={getSubtitle({ date, time, endTime })}
    renderContents={() => (
      <>
        {renderContents()}
        {address ? (
          <Address name={location} {...address} />
        ) : (
          <Text small>Location to be announced!</Text>
        )}
      </>
    )}
  />
);

export default ScheduleItem;
